<script lang="ts" context="module">

    export type Platform = "mobile" | "desktop";
    export type Breakpoint = "xs" | "s" | "m" | "l" | "xl" | "xxl";
    export type Orientation = "portrait" | "landscape";

    import {readable, derived } from 'svelte/store'

    export const breakpointWeight: Readonly<Record<Breakpoint, number>> = Object.freeze({
        "xs": 10,
        "s": 20,
        "m": 30,
        "l": 40,
        "xl": 50,
        "xxl": 60,
    });

    export const gt = (breakpoint1:Breakpoint, breakpoint2:Breakpoint) => breakpointWeight[breakpoint1] > breakpointWeight[breakpoint2];
    export const eqgt = (breakpoint1:Breakpoint, breakpoint2:Breakpoint) => breakpointWeight[breakpoint1] >= breakpointWeight[breakpoint2];
    export const lt = (breakpoint1:Breakpoint, breakpoint2:Breakpoint) => breakpointWeight[breakpoint1] < breakpointWeight[breakpoint2];
    export const eqlt = (breakpoint1:Breakpoint, breakpoint2:Breakpoint) => breakpointWeight[breakpoint1] <= breakpointWeight[breakpoint2];
    export const breakpointFromTo = (input:Breakpoint, from:Breakpoint, to:Breakpoint) => breakpointWeight[input] >=  breakpointWeight[from] && breakpointWeight[input] <= breakpointWeight[to] ;

    export const breakpoint = readable<Breakpoint>("l",(set)=>{
        const breakpoints:Array<{
            value:Breakpoint,
            mediaquery: MediaQueryList
        }> = [
            { value: "xs", mediaquery: window.matchMedia("(max-width:  479px)") },
            { value: "s", mediaquery: window.matchMedia("(min-width:  480px) and (max-width:  719px)") },
            { value: "m", mediaquery: window.matchMedia("(min-width:  720px) and (max-width:  959px)") },
            { value: "l", mediaquery: window.matchMedia("(min-width:  960px) and (max-width: 1439px)") },
            { value: "xl", mediaquery: window.matchMedia("(min-width: 1440px) and (max-width: 1919px)") },
            { value: "xxl", mediaquery: window.matchMedia("(min-width: 1920px)") },
        ];

        for (let key in breakpoints) {
            let breakpoint = breakpoints[key];

            //set the current breakpoint
            if (breakpoint.mediaquery.matches === true) {
                // EventBus.$emit("breakpoint", breakpoint.value);
                set(breakpoint.value)
            }
            breakpoint.mediaquery.addEventListener('change',(event) => {
                if (event.matches === true) {
                    // EventBus.$emit("breakpoint", breakpoint.value);
                    set(breakpoint.value)
                }
            });
        }
    });

    export const platform = derived(breakpoint, $breakpoint => {
        if ($breakpoint == "xs" || $breakpoint == "s") {
            return 'mobile' as Platform;
        } else {
            return 'desktop' as Platform;
        }
    }); 
       
    export const orientation = readable<Orientation>("landscape",(set)=>{
        const orientations:Array<{
            value:Orientation,
            mediaquery: MediaQueryList
        }> = [
            { value: "portrait", mediaquery: window.matchMedia("(orientation: portrait)") },
            { value: "landscape", mediaquery: window.matchMedia("(orientation: landscape)") },
        ];

        for (let key in orientations) {
            let orientation = orientations[key];

            //set the current orientation
            if (orientation.mediaquery.matches === true) {
                set(orientation.value)
            }

            orientation.mediaquery.addEventListener('change',(event) => {
                if (event.matches === true) {
                    set(orientation.value)
                }
            });
        }
    });


</script>
