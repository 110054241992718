<script lang="ts">
    import { loc } from "../store/loc.store";
</script>

<svelte:head>
    <title>{$loc.map("locKey_website_title") || "HTS TENTIQ 3D Konfigurator"}</title>

    <meta
        name="description"
        content={$loc.map("locKey_meta_description") ||
            "Firmendetails: HTS TENTIQ, Hinter der Schlagmühle 1, 63699 Kefenrod, Deutschland. Telefon: +49 (0) 60 49 95 100, Fax: +49 (0) 60 49 95 10 20."}
    />

    <meta property="og:type" content="article" />
    <meta property="og:title" content={$loc.map("locKey_website_title")} />
    <meta property="og:description" content={$loc.map("locKey_meta_description")} />
</svelte:head>
