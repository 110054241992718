import { LocalizationData } from "shared/lib/localization/loc.data";
import type { Localization, LocalizationSetting } from "shared/lib/localization/loc.types";
import { builtLut, getMapper, isLanguage } from "shared/lib/localization/loc.utils";
import { derived, writable } from "svelte/store";
import { getSearchParams } from "../utils/url";

export const locLocalization = writable<Localization>(LocalizationData);

/**
 * Set initial language in localStorage [BEGIN]
 */
const [languageSearchParam] = getSearchParams().filter(({ key }) => key === "lang");

if (window.localStorage.getItem("language") === null && !languageSearchParam) {
    const supportedUserLanguages = window.navigator.languages
        .map((language) => language.split("-")[0])
        .map((language) => (isLanguage(language) ? language : undefined))
        .filter((i) => i);
    window.localStorage.setItem("language", supportedUserLanguages[0] ?? "en");
}

/**
 * Forced language for redirected users from the hts main page
 */
if (languageSearchParam) {
    if (isLanguage(languageSearchParam.value)) {
        window.localStorage.setItem("language", languageSearchParam.value ?? "en");
    }
}
/**
 * Set initial language in localStorage [END]
 */

const maybeLanguage = window.localStorage.getItem("language") ?? "en";
const language = isLanguage(maybeLanguage) ? maybeLanguage : "en";
console.log("language (store)", language);
export const locSettings = writable<LocalizationSetting>({ language, variation: "none" });
locSettings.subscribe(($locSettings) => {
    window.localStorage.setItem("language", $locSettings.language);
});

export const locLut = derived([locLocalization, locSettings], ([$localization, $settings]) => {
    const enableFallback = import.meta.env.VITE_UI_MODE === "production";
    return builtLut($localization, $settings, enableFallback);
});

export const loc = derived(locLut, ($lut) => {
    return getMapper($lut);
});
