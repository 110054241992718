import type { ZHTS_KONF_USER_CRM_ADR } from "shared/lib/interfaces/sap/ZHTS_KONF_USER_CRM";
import { writable } from "svelte/store";
import { isLoggedIn, login as apiLogin, userDetails } from "../services/fetch.api";

export type User = {
    isLoggedIn: boolean;
    email?: string;
    firstname?: string;
    lastname?: string;
    currentDebitor?: ZHTS_KONF_USER_CRM_ADR;
};

export const userStore = writable<User>(
    {
        isLoggedIn: false,
        email: undefined,
        firstname: undefined,
        lastname: undefined,
        currentDebitor: undefined,
    },
    (set) => {
        isLoggedIn()
            .then(async (state) => {
                if (state) {
                    const { firstname, lastname, email } = await userDetails();
                    const localStorageCurrentDebitor: string | null = localStorage.getItem("currentDebitor");
                    // const { currentDebitor } = localStorageCurrentDebitor ? JSON.parse(localStorageCurrentDebitor) : await currentDebitor();
                    const currentDebitor: ZHTS_KONF_USER_CRM_ADR | undefined = localStorageCurrentDebitor
                        ? (JSON.parse(localStorageCurrentDebitor) as ZHTS_KONF_USER_CRM_ADR)
                        : undefined;

                    set({
                        isLoggedIn: state,
                        email,
                        firstname,
                        lastname,
                        currentDebitor,
                    });
                }
            })
            .catch((error: Error) => console.error(error));
    }
);

export const login = async (email: string, password: string) => {
    try {
        const successful = await apiLogin(email, password);
        const { firstname, lastname } = await userDetails();

        if (successful) {
            userStore.set({
                isLoggedIn: successful,
                firstname,
                lastname,
                email,
                currentDebitor: undefined,
            });
        }

        return successful;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const logout = () => {
    userStore.set({
        isLoggedIn: false,
        email: undefined,
        firstname: undefined,
        lastname: undefined,
        currentDebitor: undefined,
    });

    localStorage.removeItem("accessToken");
    localStorage.removeItem("currentDebitor");
    location.reload();
};

export const setDebitor = (debitor: ZHTS_KONF_USER_CRM_ADR) => {
    localStorage.setItem("currentDebitor", JSON.stringify(debitor));

    userStore.update((user) => ({
        ...user,
        currentDebitor: debitor,
    }));
};

export const removeDebitor = () => {
    localStorage.removeItem("currentDebitor");

    userStore.update((user) => ({
        ...user,
        currentDebitor: undefined,
    }));
};
