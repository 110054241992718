<script>
    import Modal from "../Modal.svelte";
    import ButtonAtom from "../../../atoms/ButtonAtom.svelte";
    import InputAtom from "../../../atoms/InputAtom.svelte";
    import ErrorMessageComponent from "../../ErrorMessageComponent.svelte";
    import { loc } from "../../../store/loc.store";

    let id = "";
    export let error = false;
    export let errorMessage = "";
</script>

<Modal backdrop={true} let:close let:dispatchModalAction on:modalAction on:close applyErrorStyle={error}>
    <svelte:fragment slot="header">
        <h2>{$loc.map("locKey_modal_id_input_headline")}</h2>
    </svelte:fragment>

    <p>{$loc.map("locKey_modal_id_input_instructions")}</p>

    <ErrorMessageComponent {errorMessage}>
        <form on:submit|preventDefault={() => dispatchModalAction({ action: "open", payload: { id } })}>
            <InputAtom id="id-input" {error} placeholder={$loc.map("locKey_modal_id")} bind:value={id}>
                <i slot="prefix" class="zmdi zmdi-key" />
            </InputAtom>
        </form>
    </ErrorMessageComponent>

    <svelte:fragment slot="footer">
        <ButtonAtom color="secondary" size="small" on:click={close}>
            {$loc.map("locKey_cancel")}
        </ButtonAtom>

        <ButtonAtom
            color="primary"
            size="small"
            on:click={() => {
                dispatchModalAction({ action: "open", payload: { id } });
            }}
        >
            {$loc.map("locKey_open")}
            <i class="zmdi zmdi-long-arrow-right" />
        </ButtonAtom>
    </svelte:fragment>
</Modal>

<style lang="scss">
    p {
        margin: 0;
        line-height: 1.5;
    }
</style>
