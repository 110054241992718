<script lang="ts">
	export let errorMessage:string = "";
</script>

<slot />
{#if errorMessage}
    <div class="error-message">{errorMessage}</div>
{/if}

<style lang="scss" type="text/scss">
    @use "../scss/vars" as *;
    @use "../scss/rem" as *;

    .error-message {
        color: var(--color-error-100);
        font-size: rem(10);
    }
</style>
