<script context="module" lang="ts">
    export interface SubmitModalProps {
        headline: string,
        text: string,
        submitButtonText?: string,
        hideSubmitButton?: boolean;
        closeButtonText?: string,
        error?: boolean,
    }
</script>

<script lang="ts">
    import Modal from "../Modal.svelte";
    import ButtonAtom from "../../../atoms/ButtonAtom.svelte";
    import { loc } from "../../../store/loc.store";

    export let headline: string = "";
    export let text: string = "";
    export let submitButtonText: string = `${$loc.map("locKey_submit")} <i class="zmdi zmdi-long-arrow-right" />`;
    export let hideSubmitButton: boolean = false;
    export let closeButtonText: string = `${$loc.map("locKey_close")}`;
    export let error: boolean = false;
</script>

<Modal backdrop={true} applyErrorStyle={error} let:close let:dispatchModalAction on:modalAction>
    <svelte:fragment slot="header">
        <h2>{headline}</h2>
    </svelte:fragment>

    <p>{text}</p>

    <svelte:fragment slot="footer">
        <ButtonAtom
            color="primary"
            on:click={() => {
                dispatchModalAction({ action: "close" });
                close();
            }}
        >
            {@html closeButtonText}
        </ButtonAtom>

        {#if hideSubmitButton === false}
            <ButtonAtom color="primary" on:click={() => dispatchModalAction({ action: "submit" })}>
                {@html submitButtonText}
            </ButtonAtom>
        {/if}
    </svelte:fragment>
</Modal>

<style lang="scss">
    p {
        line-height: 1.5;
    }
</style>
