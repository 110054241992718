import type { State } from "config-state/src/config/Tent_B/tentB.config";
import type { BOMTransformerResult } from "config-state/src/config/Tent_B/BOM/bom.types";
import type {
    CreateConfigurationDTO,
    ProductOverviewResult,
    ResultConfigurationOverviewDTO,
    ResultSaveConfigurationDTO,
    UpsertConfigurationContextBody,
} from "shared/lib/interfaces/api";
import type { FormRequest } from "shared/lib/interfaces/formRequest";
import type { Order } from "shared/lib/interfaces/product";
import type {
    DataportClientGroup,
    DataportCountry,
    DataportCurrency,
    DataportLanguage,
} from "shared/lib/interfaces/sap/sap.types";
import type { ZHTS_KONF_USER_CRM_ADR } from "shared/lib/interfaces/sap/ZHTS_KONF_USER_CRM";
import type { Country } from "shared/lib/interfaces/country";

/*eslint-disable */
const apiURL = () => (window as any)["REDBUILD"]?.Options?.apiURL || "";

export async function login(email: string, password: string): Promise<boolean> {
    return post<{
        access_token: string;
    }>(`${apiURL()}/api/v1/auth/login/`, JSON.stringify({ email, password }))
        .then((data) => {
            if (data.access_token !== null) {
                localStorage.setItem("accessToken", data.access_token);
            }

            return true;
        })
        .catch(() => false);
}

export async function isLoggedIn(): Promise<boolean> {
    try {
        const response = await get<{
            access_token: string;
        }>(`/api/v1/auth/isloggedin`, "");
        localStorage.setItem("accessToken", response.access_token);

        return response.access_token !== undefined;
    } catch {
        return false;
    }
}

export async function userRegister(username: string): Promise<boolean> {
    try {
        return post<boolean>("/api/v1/user/register", JSON.stringify({ username }));
    } catch {
        return false;
    }
}

export async function userResetPassword(email: string): Promise<boolean> {
    try {
        return post<boolean>("/api/v1/user/reset_password", JSON.stringify({ email }));
    } catch {
        return false;
    }
}
export async function userSavePassword(token: string, password: string): Promise<boolean> {
    return post<boolean>("/api/v1/user/save_password", JSON.stringify({ token, password }));
}

export async function userDetails() {
    try {
        return get<{
            email: string;
            firstname: string;
            lastname: string;
            created_at: Date;
        }>("/api/v1/user/details");
    } catch {
        throw "Cannot retrieve User details.";
    }
}

export async function productOrder(bom: BOMTransformerResult): Promise<Order> {
    return post<Order>("/api/v1/product/order", JSON.stringify(bom));
}

export function overview(sales_country: Country): Promise<ProductOverviewResult> {
    return get<ProductOverviewResult>(`/api/v1/product/overview/?sales_country=${sales_country}`);
}

export function getProductCountries(): Promise<Array<{ label: string; country: Country }>> {
    return get<Array<{ label: string; country: Country }>>(`/api/v1/product/countries`);
}

export function debitorOverview(): Promise<ZHTS_KONF_USER_CRM_ADR[]> {
    return get<ZHTS_KONF_USER_CRM_ADR[]>("/api/v1/user/debitors/");
}

export function debitorConfigurations(ADRCRMID?: string): Promise<ResultConfigurationOverviewDTO[]> {
    const query = ADRCRMID !== undefined ? `?ADRCRMID=${ADRCRMID}` : "";
    return get<ResultConfigurationOverviewDTO[]>(`/api/v1/configuration/debitor${query}`);
}

export function userConfigurations(): Promise<ResultConfigurationOverviewDTO[]> {
    return get<ResultConfigurationOverviewDTO[]>("/api/v1/configuration/mine/");
}

export function getConfigurationContext(hash_id: string): Promise<any> {
    return get(`/api/v1/configuration/context/${hash_id}`);
}

export function deleteConfigurationContext(hash_id: string): Promise<any> {
    return post(`/api/v1/configuration/delete/${hash_id}`, null);
}

export function upsertConfigurationContext(hash_id: string, body: UpsertConfigurationContextBody): Promise<any> {
    return post(`/api/v1/configuration/upsert/${hash_id}`, JSON.stringify(body));
}

export function loadStateMock(): Promise<State> {
    return get(`/api/v1/product/state/`);
}

export function findConfiguration(hash_id: string): Promise<any> {
    return get<any>(`/api/v1/configuration/find/${hash_id}`);
}

export function saveConfiguration(
    data: CreateConfigurationDTO,
    ADRCRMID?: string
): Promise<ResultSaveConfigurationDTO> {
    const query = ADRCRMID !== undefined ? `?ADRCRMID=${ADRCRMID}` : "";
    return post<ResultSaveConfigurationDTO>(`/api/v1/configuration${query}`, JSON.stringify(data));
}

export function sendSaveConfigurationMail(id: string, hash_id: string, mailAddress: string): Promise<boolean> {
    return post<boolean>(
        `/api/v1/configuration/send_save_configuration_mail/${id}/${hash_id}`,
        JSON.stringify({ to: mailAddress })
    );
}

export function createSapOffer(id: string, hash_id: string, requestData: FormRequest): Promise<boolean> {
    return post(`/api/v1/configuration/create_sap_offer/${id}/${hash_id}`, JSON.stringify(requestData));
}

export function sendRequestMail(id: string, hash_id: string, requestData: FormRequest): Promise<boolean> {
    return post<boolean>(`/api/v1/configuration/send_request_mail/${id}/${hash_id} `, JSON.stringify(requestData));
}

export function getSapCountries() {
    return get<(DataportCountry | undefined)[]>(`/api/v1/dataport/countries`);
}

export function getSapCurrencies() {
    return get<(DataportCurrency | undefined)[]>(`/api/v1/dataport/currencies`);
}

export function getSapLanguages() {
    return get<(DataportLanguage | undefined)[]>(`/api/v1/dataport/languages`);
}

export function getSapClientGroups() {
    return get<(DataportClientGroup | undefined)[]>(`/api/v1/dataport/clientgroups`);
}

// ********************************************************************************* //
// ********************************************************************************* //
// ********************************************************************************* //
//use for new resources
export function post<T>(
    url: string,
    params: string,
    accessToken?: string,
    authMethod = "Bearer",
    responseType: XMLHttpRequestResponseType = ""
): Promise<T> {
    return new Promise<T>((resolve, reject) => {
        const http = new XMLHttpRequest();

        http.open("POST", url, true);

        if (responseType) {
            http.responseType = responseType;
        }
        //Send the proper header information along with the request
        http.setRequestHeader("Content-type", "application/json");
        http.setRequestHeader("Content-Language", localStorage.getItem("language") || "en");

        const cachedAccessToken = localStorage.getItem("accessToken");
        accessToken = cachedAccessToken !== null ? cachedAccessToken : accessToken;

        if (accessToken) {
            http.setRequestHeader("Authorization", `${authMethod} ${accessToken}`);
        }

        http.onreadystatechange = function () {
            //Call a function when the state changes.
            if (http.readyState === 4 && http.status >= 200 && http.status < 300) {
                if (responseType === "arraybuffer") {
                    resolve(http.response);
                } else {
                    const responsePlainOrJson = http
                        .getResponseHeader("content-type")
                        .toLowerCase()
                        .includes("application/json")
                        ? JSON.parse(http.response || http.responseText)
                        : http.response || http.responseText;

                    // TODO: enable localization again?
                    // if (http.getResponseHeader("Localization") === "true") {
                    //     LocalizationService.get().batchAdd(collectLocalization(responsePlainOrJson));
                    // }

                    resolve(responsePlainOrJson);
                }
            } else if (http.readyState === 4) {
                const responsePlainOrJson = http
                    .getResponseHeader("content-type")
                    .toLowerCase()
                    .includes("application/json")
                    ? JSON.parse(http.response || http.responseText)
                    : http.response || http.responseText;

                reject(responsePlainOrJson);
            }
        };

        http.onerror = function (err) {
            reject(err);
        };

        try {
            http.send(params);
        } catch (err) {
            console.error(err);
        }
    });
}

const getCache: { [cacheKey: string]: Promise<any> | undefined } = {};

//use for reading resources
export function get<T>(
    url: string,
    params: string = "",
    accessToken?: string,
    authMethod: string = "Bearer",
    options?: { withCredentials: boolean },
    useCache: boolean = false
): Promise<T> {
    const cacheKey = btoa(
        JSON.stringify({
            url,
            params,
            accessToken,
            authMethod,
            options,
        })
    );

    if (useCache === true && getCache[cacheKey] !== undefined) {
        return getCache[cacheKey] as Promise<T>;
    }

    const getPromise = new Promise<T>((resolve, reject) => {
        const http = new XMLHttpRequest();

        http.open("GET", url, true);

        //Send the proper header information along with the request
        http.setRequestHeader("Content-type", "application/json");
        http.setRequestHeader("Content-Language", localStorage.getItem("language") || "en");

        const cachedAccessToken = localStorage.getItem("accessToken");
        accessToken = cachedAccessToken !== null ? cachedAccessToken : accessToken;

        if (accessToken) {
            http.setRequestHeader("Authorization", `${authMethod} ${accessToken}`);
        }

        if (options) {
            for (const key in options) {
                http[key] = options[key];
            }
        }

        http.onreadystatechange = function () {
            //Call a function when the state changes.
            if (http.readyState === 4 && http.status >= 200 && http.status < 300) {
                const responsePlainOrJson = http
                    .getResponseHeader("content-type")
                    .toLowerCase()
                    .includes("application/json")
                    ? JSON.parse(http.response || http.responseText)
                    : http.response || http.responseText;

                // TODO: enable localization again?
                // if (http.getResponseHeader("Localization") === "true") {
                //     LocalizationService.get().batchAdd(collectLocalization(responsePlainOrJson));
                // }

                resolve(responsePlainOrJson);
            } else if (http.readyState === 4) {
                const responsePlainOrJson = http
                    .getResponseHeader("content-type")
                    .toLowerCase()
                    .includes("application/json")
                    ? JSON.parse(http.response || http.responseText)
                    : http.response || http.responseText;

                reject(responsePlainOrJson);
            }
        };

        http.onerror = function (err) {
            reject(err);
        };

        try {
            http.send(params);
        } catch (err) {
            console.error(err);
        }
    });

    getCache[cacheKey] = getPromise.catch(() => {
        delete getCache[cacheKey];
    });

    return getPromise;
}

//use to update resources
function put<T>(url: string, params: string, accessToken?: string, options?: { withCredentials: boolean }): Promise<T> {
    return new Promise<T>((resolve, reject) => {
        const http = new XMLHttpRequest();

        http.open("PUT", url, true);

        //Send the proper header information along with the request
        http.setRequestHeader("Content-type", "application/json");
        http.setRequestHeader("Content-Language", localStorage.getItem("language") || "en");

        const cachedAccessToken = localStorage.getItem("accessToken");
        accessToken = cachedAccessToken !== null ? cachedAccessToken : accessToken;

        if (accessToken) {
            http.setRequestHeader("Authorization", `Bearer ${accessToken}`);
        }

        if (options) {
            for (const key in options) {
                http[key] = options[key];
            }
        }

        http.onreadystatechange = function () {
            //Call a function when the state changes.
            if (http.readyState === 4 && http.status >= 200 && http.status < 300) {
                const responsePlainOrJson = http
                    .getResponseHeader("content-type")
                    .toLowerCase()
                    .includes("application/json")
                    ? JSON.parse(http.response || http.responseText)
                    : http.response || http.responseText;

                // TODO: enable localization again?
                // if (http.getResponseHeader("Localization") === "true") {
                //     LocalizationService.get().batchAdd(collectLocalization(responsePlainOrJson));
                // }

                resolve(responsePlainOrJson);
            } else if (http.readyState === 4) {
                reject(new Error("httpget: error at validation @ " + url));
            }
        };

        http.onerror = function (err) {
            reject(err);
        };

        try {
            http.send(params);
        } catch (err) {
            console.error(err);
        }
    });
}
