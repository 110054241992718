import type { RouteInfoData } from "@easyroute/core/dist/types";
import { Platform } from "redtyped/lib/core/Platform";

let hasChecked = false;
let deviceSupportsWebGL = false;
export function WebGLCheck(to: RouteInfoData, from: RouteInfoData, next: Function) {
    // alert(to.fullPath);

    if (to.fullPath === "/nowebgl" || to.fullPath === "/nowebgl/") {
        next();
        return;
    }

    // retry if not found?
    if (hasChecked === false) {
        deviceSupportsWebGL = Platform.get().supportWebGL;
        hasChecked = true;

        // GOTO nowebglpage
        if (!deviceSupportsWebGL) {
            next("/nowebgl");
            return;
        }
    }

    next();
}
