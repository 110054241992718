<script lang="ts">
    import { userResetPassword } from "../../../services/fetch.api";
    import Modal from "../Modal.svelte";
    import { loc } from "../../../store/loc.store";
    import { router } from "../../../router";

    import InputAtom from "../../../atoms/InputAtom.svelte";
    import ButtonAtom from "../../../atoms/ButtonAtom.svelte";
    import ErrorMessageComponent from "../../ErrorMessageComponent.svelte";

    let email: string = "";
    let passwordResetMailSent: boolean = false;
    export let isLoggedIn: boolean = false;
    let error: boolean = false;
    $: errorMessage = error ? $loc.map("locKey_error_happened") : "";

    const onSubmit = () => {
        userResetPassword(email)
            .then((wasSuccessful) => {
                passwordResetMailSent = wasSuccessful;
                error = false;
            })
            .catch((errorData: Error) => {
                console.error(errorData);
                error = true;
            });
    };

    const reSubmit = () => {
        onSubmit();
    };

    const handleClose = (close: () => void) => {
        if (isLoggedIn === false) {
            router.push("/");
        }

        close();
    };
</script>

<Modal backdrop={true} closeable={isLoggedIn} let:close applyErrorStyle={error}>
    <svelte:fragment slot="header">
        <h2>
            {isLoggedIn ? $loc.map("locKey_password_change") : $loc.map("locKey_password_forgot")}
        </h2>
    </svelte:fragment>

    <!-- DEFAULT SLOT [BEGIN] -->
    {#if passwordResetMailSent}
        <p>{$loc.map("locKey_password_reset_mail_sent_text")}</p>
    {:else}
        <p>{$loc.map("locKey_password_reset_text")}</p>
        <ErrorMessageComponent {errorMessage}>
            <form on:submit|preventDefault={() => onSubmit()}>
                <InputAtom
                    id="windows-username"
                    bind:value={email}
                    placeholder={$loc.map("locKey_email_adress")}
                    {error}
                >
                    <i slot="prefix" class="zmdi zmdi-lock" />
                </InputAtom>
            </form>
        </ErrorMessageComponent>
    {/if}
    <!-- DEFAULT SLOT [END] -->

    <svelte:fragment slot="footer">
        {#if passwordResetMailSent}
            <ButtonAtom color="secondary" on:click={() => handleClose(close)}>
                {$loc.map("locKey_close")}
            </ButtonAtom>
            <ButtonAtom color="primary" on:click={reSubmit}>
                <i class="zmdi zmdi-refresh-sync" />
                {$loc.map("locKey_send_again")}
            </ButtonAtom>
        {:else}
            <ButtonAtom color="secondary" size="small" on:click={() => handleClose(close)}>
                {isLoggedIn ? $loc.map("locKey_cancel") : $loc.map("locKey_back_to_login")}
            </ButtonAtom>
            <ButtonAtom color="primary" size="small" on:click={onSubmit}>
                {isLoggedIn ? $loc.map("locKey_change_password") : $loc.map("locKey_send")}
                <i class="zmdi zmdi-long-arrow-right" />
            </ButtonAtom>
        {/if}
    </svelte:fragment>
</Modal>

<style lang="scss">
    @use "../../../scss/vars" as *;
    @use "../../../scss/rem" as *;

    p {
        margin-bottom: rem(8);
        line-height: 1.5;
    }
</style>
