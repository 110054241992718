<script lang="ts">
    import type { EInputAtomShape, EInputAtomSize} from "./InputAtom.type";
    import {createEventDispatcher} from "svelte"

    const dispatch = createEventDispatcher<{
        change:string
    }>();

	export let active:boolean = false;
	export let disabled:boolean = false;
	export let error:boolean = false;
	export let value:string | number = "";
	export let placeholder:string = "";
	export let color:string = "transparent";
	export let id:string;
	export let type:"text" | "password" = "text";

    export let size: EInputAtomSize= 'big';
    export let shape: EInputAtomShape= 'default';

    function handleChangeEvent(event:Event){
        dispatch("change", value.toString());
    }

    function typeAction(node) {
		node.type = type;
	}

</script>

<div
    class="input-box-atom {shape} {size} {color}"
    class:validation-error={error}
    class:disabled
    class:active
    class:error
>
    {#if $$slots.prefix}
        <div class="prefix">
            <slot name="prefix" />
        </div>
    {/if}
    <input use:typeAction {id} {placeholder} bind:value on:blur on:input on:change={handleChangeEvent} />
    {#if $$slots.suffix}
        <div class="suffix">
            <slot name="suffix" />
        </div>
    {/if}
</div>

<style lang="scss" type="text/scss">
    @use "sass:math";
    @import "../scss/vars";
    @import "../scss/rem";
    $gap: rem(2);
    $margin: 10;

    .input-box-atom {
        min-height: rem(30);
        position: relative;
        background-color: var(--color-primary-4);
        overflow: hidden;
        box-sizing: border-box;
        display: flex;
        align-items: center;

        .prefix {
            margin-left: rem($margin);

            :global(i) {
                color: var(--color-secondary-48);
                font-size: rem(13);
            }
        }

        .suffix {
            margin-right: rem($margin);
        }

        input {
            width: 100%;
            padding: 0 rem($margin);
            margin: rem(math.div($margin, 4)) 0;
            box-sizing: border-box;
            border: none;
            line-height: 1;
            font-size: rem(12);
            font-weight: $font-weight-regular;
            background-color: transparent;
            color: var(--color-secondary-88);

            &:focus {
                border: none;
                outline: none;
            }

            @mixin placeholder() {
                color: var(--color-secondary-48);
                font-size: rem(10);
            }
            &::-webkit-input-placeholder {
                /* Chrome/Opera/Safari */
                @include placeholder();
            }
            &::-moz-placeholder {
                /* Firefox 19+ */
                @include placeholder();
            }
            &:-ms-input-placeholder {
                /* IE 10+ */
                @include placeholder();
            }
            &:-moz-placeholder {
                /* Firefox 18- */
                @include placeholder();
            }
        }

        &::after {
            content: " ";
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 1px;
            background-color: var(--color-primary-60);
            transition: height 0.1s ease;
        }

        &:focus-within {
            &::after {
                height: rem(2);
                background-color: var(--color-primary-100);
            }
        }

        &.validation-error {
            background-color: var(--color-error-4);

            &::after {
                background-color: var(--color-error-100);

                &:hover,
                &:focus,
                &:focus-within {
                    background-color: var(--color-error-100);
                }
            }
        }
    }
</style>
