<script lang="ts">
    import Modal from "../Modal.svelte";
    import type { SelectableAtomItem } from "../../../atoms/SelectableAtomInterface";
    import ButtonAtom from "../../../atoms/ButtonAtom.svelte";
    import InputAtom from "../../../atoms/InputAtom.svelte";
    import SelectableAtom from "../../../atoms/SelectableAtom.svelte";
    import { loc } from "../../../store/loc.store";
    import type { ZHTS_KONF_USER_CRM_ADR } from "shared/lib/interfaces/sap/ZHTS_KONF_USER_CRM";

    export let currentDebitor: ZHTS_KONF_USER_CRM_ADR | undefined = undefined;

    let name = "";

    let configSaveOptions: SelectableAtomItem[] = [
        {
            id: "1",
            value: "my-profile",
            label: $loc.map("locKey_save_in_my_profile"),
            selected: true,
        },
        {
            id: "2",
            value: "debitor-profile",
            label: $loc.map("locKey_save_in_debitor_profile"),
            selected: false,
            disabled: currentDebitor === undefined,
        },
    ];

    $: isProfileEnabled = configSaveOptions.find((saveOption) => saveOption.id === "2").selected;
</script>

<Modal backdrop={true} let:close let:dispatchModalAction on:modalAction>
    <svelte:fragment slot="header">
        <h2>{$loc.map("locKey_modal_save_config")}</h2>
    </svelte:fragment>

    <form on:submit|preventDefault={() => dispatchModalAction({ action: "save", payload: { name, currentDebitor } })}>
        <InputAtom id="name-input" placeholder={$loc.map("locKey_modal_config_name")} bind:value={name}>
            <i slot="prefix" class="zmdi zmdi-email" />
        </InputAtom>

        <SelectableAtom
            items={configSaveOptions}
            let:item
            on:click={(e) => {
                configSaveOptions.forEach((saveOption) => {
                    if (saveOption.selected) {
                        saveOption.selected = false;
                    }

                    if (saveOption.id === e.detail.id) {
                        saveOption.selected = true;
                    }
                });
                configSaveOptions = configSaveOptions;
            }}
        >
            {item.label}
        </SelectableAtom>
    </form>

    {#if currentDebitor && isProfileEnabled}
        <div class="customer-profile">
            <p class="customer-number">{currentDebitor.ADRCRMID}</p>
            <p class="customer-name">{currentDebitor.ADRNAME1}</p>
        </div>
    {/if}

    <svelte:fragment slot="footer">
        <ButtonAtom color="secondary" size="small" on:click={close}>
            {$loc.map("locKey_cancel")}
        </ButtonAtom>
        <ButtonAtom
            color="primary"
            size="small"
            on:click={() => dispatchModalAction({ action: "save", payload: { name, currentDebitor } })}
        >
            {$loc.map("locKey_save")}
            <i class="zmdi zmdi-long-arrow-right" />
        </ButtonAtom>
    </svelte:fragment>
</Modal>

<style lang="scss" type="text/scss">
    @use "../../../scss/vars" as *;
    @use "../../../scss/rem" as *;

    form {
        display: flex;
        flex-direction: column;
        gap: rem(15);
    }

    .customer-profile {
        margin-top: rem(10);

        > * {
            margin-top: 0;
            margin-bottom: rem(6);
        }

        .customer-number {
            font-size: rem(10);
        }

        .customer-name {
            font-size: rem(12);
        }
    }
</style>
