<script lang="ts">
    import IconFont from "material-design-iconic-font/dist/css/material-design-iconic-font.css";
    import NormalizeCSS from "normalize.css/normalize.css";
    import { EasyrouteProvider, RouterOutlet } from "@easyroute/svelte";
    import { router } from "./router";
    import { backdropActive } from "./components/modals/Modal.svelte";
    import ModalManager from "./components/modals/ModalManager.svelte";
    import TagManager from "./components/TagManager.svelte";
    import DebugSidebar from "./components/DebugSidebar.svelte";
    import PreloaderAtom from "./atoms/PreloaderAtom.svelte";
    import { loading } from "./store/session.store";
    import Tracking from "./Tracking.svelte";

    const SHOW_DEBUG_UI: boolean = import.meta.env.VITE_SHOW_DEBUG_UI === "true";
    const ENABLE_TRACKING: boolean = import.meta.env.PROD === true;
</script>

<TagManager />

{#if ENABLE_TRACKING}
    <Tracking />
{/if}

<EasyrouteProvider {router}>
    <RouterOutlet />
    {#if $loading.page && !$loading.mutation}
        <PreloaderAtom />
    {/if}
</EasyrouteProvider>

{#if $backdropActive}
    <div class="backdrop" />
{/if}

<ModalManager />

{#if SHOW_DEBUG_UI}
    <DebugSidebar />
{/if}

<style lang="scss" type="text/scss">
    @use "sass:math";
    @use "./scss/colors.scss";
    @use "./scss/vars" as *;
    @use "./scss/fonts.scss";
    @use "./scss/rem" as *;
    @use "./scss/interpolate" as *;

    :root {
        @each $key, $value in colors.$map {
            --#{$key}: #{$value};
        }
    }

    :global(html),
    :global(body) {
        padding: 0;
        margin: 0;
        width: 100%;
        height: 100%;
        background-color: var(--default-background-color);
        color: var(--color-secondary-76);
        font-family: $default-font-family;
        font-weight: $font-weight-regular;
        @include fluid-type(font-size, $media-xs, $media-lg, 12px, 10px);

        @media screen and (min-width: $media-lg) {
            @include fluid-type(font-size, $media-lg, $media-xl, 12px, 13px);
        }

        scroll-behavior: smooth;
        text-rendering: geometricPrecision;
    }

    :global {
        /* Width */
        &::-webkit-scrollbar {
            width: rem(7);
            height: rem(7);
            background: var(--color-primary-32);
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: var(--color-primary-100);
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: var(--color-primary-100);
        }

        a {
            text-decoration: none;
            color: var(--signal-dark);
        }

        p.badge {
            border-radius: rem($radius);
            position: relative;
            background-color: var(--color-primary-4);
            padding: rem(math.div($radius, 4)) rem(math.div($radius, 2));
            font-size: rem(12);
            font-weight: $font-weight-bold;
            color: var(--color-primary-100);
            padding: rem(10) rem(16);
            margin: 0;
            margin-bottom: rem(30);
        }

        .loading {
            position: relative;

            &::after {
                position: absolute;
                content: "";
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: var(--default-background-color);
                opacity: 0.75;
                background-image: url("data:image/svg+xml;base64,PHN2ZyBjbGFzcz0ibGRzLW1lc3NhZ2UiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHZpZXdCb3g9IjAgMCAxMDAgMTAwIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJ4TWlkWU1pZCI+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMjUgNTApIj4NCjxjaXJjbGUgY3g9IjAiIGN5PSIwIiByPSI2IiBmaWxsPSIjMDAwMDAwIiB0cmFuc2Zvcm09InNjYWxlKDAuOTk4MzI2IDAuOTk4MzI2KSI+DQogIDxhbmltYXRlVHJhbnNmb3JtIGF0dHJpYnV0ZU5hbWU9InRyYW5zZm9ybSIgdHlwZT0ic2NhbGUiIGJlZ2luPSItMC40NjY2NjY2NjY2NjY2NjY2cyIgY2FsY01vZGU9InNwbGluZSIga2V5U3BsaW5lcz0iMC4zIDAgMC43IDE7MC4zIDAgMC43IDEiIHZhbHVlcz0iMDsxOzAiIGtleVRpbWVzPSIwOzAuNTsxIiBkdXI9IjEuNHMiIHJlcGVhdENvdW50PSJpbmRlZmluaXRlIj48L2FuaW1hdGVUcmFuc2Zvcm0+DQo8L2NpcmNsZT4NCjwvZz48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSg1MCA1MCkiPg0KPGNpcmNsZSBjeD0iMCIgY3k9IjAiIHI9IjYiIGZpbGw9IiMwMDAwMDAiIHRyYW5zZm9ybT0ic2NhbGUoMC43NjE2NjkgMC43NjE2NjkpIj4NCiAgPGFuaW1hdGVUcmFuc2Zvcm0gYXR0cmlidXRlTmFtZT0idHJhbnNmb3JtIiB0eXBlPSJzY2FsZSIgYmVnaW49Ii0wLjIzMzMzMzMzMzMzMzMzMzNzIiBjYWxjTW9kZT0ic3BsaW5lIiBrZXlTcGxpbmVzPSIwLjMgMCAwLjcgMTswLjMgMCAwLjcgMSIgdmFsdWVzPSIwOzE7MCIga2V5VGltZXM9IjA7MC41OzEiIGR1cj0iMS40cyIgcmVwZWF0Q291bnQ9ImluZGVmaW5pdGUiPjwvYW5pbWF0ZVRyYW5zZm9ybT4NCjwvY2lyY2xlPg0KPC9nPjxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKDc1IDUwKSI+DQo8Y2lyY2xlIGN4PSIwIiBjeT0iMCIgcj0iNiIgZmlsbD0iIzAwMDAwMCIgdHJhbnNmb3JtPSJzY2FsZSgwLjMwMDI4NyAwLjMwMDI4NykiPg0KICA8YW5pbWF0ZVRyYW5zZm9ybSBhdHRyaWJ1dGVOYW1lPSJ0cmFuc2Zvcm0iIHR5cGU9InNjYWxlIiBiZWdpbj0iMHMiIGNhbGNNb2RlPSJzcGxpbmUiIGtleVNwbGluZXM9IjAuMyAwIDAuNyAxOzAuMyAwIDAuNyAxIiB2YWx1ZXM9IjA7MTswIiBrZXlUaW1lcz0iMDswLjU7MSIgZHVyPSIxLjRzIiByZXBlYXRDb3VudD0iaW5kZWZpbml0ZSI+PC9hbmltYXRlVHJhbnNmb3JtPg0KPC9jaXJjbGU+DQo8L2c+PC9zdmc+");
                background-repeat: no-repeat;
                background-position: center center;
                background-size: rem(120);
                z-index: 990;
                cursor: progress;
            }

            &.modal,
            &.tooltip-info-config {
                &::after {
                    top: rem(10);
                    left: rem(10);
                    right: rem(10);
                    bottom: rem(10);
                }
            }
        }
    }

    :global(#hts-app) {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        overflow: hidden;
        //firefox custom scrollbar
        scrollbar-color: var(--color-primary-100) var(--color-primary-32);
    }

    :global(#hts-app .easyroute-outlet) {
        display: contents;

        // ↓↓↓ Fallback for contents
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    .backdrop {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 490;
        background-color: var(--color-primary-100);
        opacity: 0.6;
    }
</style>
