<script lang="ts">
    import Modal from "../Modal.svelte";
    import ButtonAtom from "../../../atoms/ButtonAtom.svelte";
    import InputAtom from "../../../atoms/InputAtom.svelte";
    import { loc } from "../../../store/loc.store";
    import { login } from "../../../store/user.store";
    import { addTrackingEvent } from "../../../services/tracking.service";

    let username: string = "";
    let password: string = "";

    const onSubmit = (email: string, password: string, callback?: () => unknown) => {
        login(email, password)
            .then((_) => callback())
            .catch(() => addTrackingEvent("login error", "login error"));
    };
</script>

<Modal backdrop={true} let:close>
    <svelte:fragment slot="header">
        <h2>{$loc.map("locKey_login")}</h2>
    </svelte:fragment>

    <img src="/img/hts_logo.svg" alt="HTS Logo" />

    <form on:submit|preventDefault={() => onSubmit(username, password, close)}>
        <InputAtom id="crm-id-input" bind:value={username} placeholder={$loc.map("locKey_modal_crm_id")}>
            <i slot="prefix" class="zmdi zmdi-account" />
        </InputAtom>

        <InputAtom id="password-input" type="password" bind:value={password} placeholder={$loc.map("locKey_password")}>
            <i slot="prefix" class="zmdi zmdi-lock" />
        </InputAtom>

        <!-- hidden input to make form submit on enter work -->
        <input type="submit" style="display: none;" />
        <!---------------------------------------------------->
    </form>

    <svelte:fragment slot="footer">
        <ButtonAtom color="primary" size="small" on:click={() => onSubmit(username, password, close)}>
            {$loc.map("locKey_login")}
            <i class="zmdi zmdi-long-arrow-right" />
        </ButtonAtom>
    </svelte:fragment>
</Modal>

<style lang="scss" type="text/scss">
    @use "../../../scss/vars" as *;
    @use "../../../scss/rem" as *;

    img {
        height: rem(25);
        align-self: flex-start;
        margin: rem(5) 0;
    }

    form {
        display: flex;
        flex-direction: column;
        gap: rem(10);
        margin-bottom: rem(15);
    }
</style>
