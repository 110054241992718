<script lang="ts" context="module">
    import { writable, readable, get } from "svelte/store";

    export const backdropActive = writable(false);

    let modalStackSet: (values: number[]) => void | undefined;
    export const modalStack = readable([] as number[], (set) => {
        modalStackSet = set;
    });

    let modalIdCounter = writable(0);

    export const getNextId = () => get(modalIdCounter);

    export type ModalActionType = {
        action: string;
        modalId: number;
        payload?: any;
    };
</script>

<script lang="ts">
    import { onDestroy, onMount } from "svelte";
    import { fade } from "svelte/transition";
    import { portal } from "svelte-portal";

    import { createEventDispatcher } from "svelte";
    const dispatch = createEventDispatcher<{
        stacked: number;
        close: number;
        modalAction: ModalActionType;
    }>();

    export let backdrop = false;
    export let closeable = true;
    export let applyErrorStyle: boolean = false;

    let visible = false;

    let id: number;

    modalIdCounter.update((next_id) => {
        id = next_id;
        return next_id + 1;
    });

    let unsub = modalStack.subscribe((stack) => {
        visible = stack[stack.length - 1] === id;
        if (visible) {
            backdropActive.update((_) => backdrop);
        }
    });

    export function close() {
        const stack = get(modalStack);

        // the last one has to clean up
        if (stack.length === 1) {
            backdropActive.update((_) => false);
        }

        modalStackSet(stack.filter((stackId) => stackId !== id));

        dispatch("close", id);
    }

    const handleWindowKeyDown = ({ key }: KeyboardEvent) => {
        if (key === "Escape" && closeable) {
            close();
        }
    };

    onMount(() => {
        const stack = get(modalStack);
        modalStackSet([...stack, id]);

        dispatch("stacked", id);
    });

    onDestroy(() => {
        unsub();
        close();
    });

    function dispatchModalAction<T>(detail: { action: string; payload?: T }) {
        dispatch("modalAction", {
            modalId: id,
            action: detail.action,
            payload: detail.payload,
        });
    }
</script>

<svelte:window on:keydown={handleWindowKeyDown} />

{#if visible}
    <div class="card-center" use:portal={"#modal-portal"}>
        <div class="card" class:error={applyErrorStyle} in:fade={{ duration: 150 }} out:fade={{ duration: 150 }}>
            {#if $$slots.header}
                <div class="header">
                    {#if closeable}
                        <div class="close-button">
                            <i class="zmdi zmdi-close" on:click={close} />
                        </div>
                    {/if}
                    <slot name="header" />
                </div>
            {/if}
            {#if $$slots.default}
                <div class="content">
                    <slot {close} {dispatchModalAction} />
                </div>
            {/if}
            {#if $$slots.footer}
                <div class="footer">
                    <slot name="footer" />
                </div>
            {/if}
        </div>
    </div>
{/if}

<style lang="scss" type="text/scss">
    @use "../../scss/rem" as *;
    @use "../../scss/vars" as *;

    .card-center {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 500;
        display: flex;
        align-items: center;
        justify-content: center;

        .card {
            position: relative;
            background-color: var(--default-background-color);
            border-radius: 1px;
            width: rem(360);
            padding: rem(20);
            border: 1px solid #ddd;
            display: flex;
            flex-direction: column;
            gap: rem(25);

            &.error {
                border-color: var(--color-error-100);

                .header {
                    :global(h2) {
                        color: var(--color-red-100);
                    }
                }
            }

            .header {
                display: flex;
                flex-flow: column; //to prevent box-collisions
                align-items: flex-start;

                :global(h2) {
                    color: var(--color-primary-100);
                    margin: 0;
                    font-size: rem(18);
                    font-weight: $font-weight-bold;
                }

                .close-button {
                    position: absolute;
                    right: rem(20);
                    margin-bottom: rem(20);
                    font-size: rem(18);
                    text-align: right;

                    i {
                        cursor: pointer;
                    }
                }
            }

            .content {
                display: flex;
                flex-direction: column;
                gap: rem(15);
                font-size: rem(14);
                line-height: rem(20);
                color: var(--color-secondary-100);

                :global {
                    p {
                        margin: 0;
                    }
                }
            }

            .footer {
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                margin-top: rem(10);

                :global {
                    button {
                        margin-right: rem(10);

                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }
            }

            :global(.footer > * > *) {
                margin-left: rem(0.5);
            }
        }
    }
</style>
