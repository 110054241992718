export const addTrackingEvent = (action: string, label: string) => {
    const dataLayer = window["dataLayer"];
    if (dataLayer) {
        console.log("Added tracking event: ", action, label);

        dataLayer.push({
            event: "generic-event",
            category: "configurator page",
            action,
            label,
        });
    }
};
